import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

  return (
    <>
      <SEO title="Privacy Policy"/>
      <main className="main-wrapper">
        <HeaderOne/>
        <BreadCrumbOne
          title="Privacy Policy"
          page="Privacy Policy"
        />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5>Last Updated On 02-Jul-2024</h5>
                    <h5 className="title">Effective Date 02-Jul-2024</h5>
                  </div>


                  <h2>Privacy Policy</h2>

                  <p>
                    This Privacy Policy describes the policies of<br/><br/>
                    Uslužna djelatnost "Devote",<br/>
                    Beogradska 11, 71123 Istočno Sarajevo,<br/>
                    Republika Srpska, Bosnia and Herzegovina,<br/>
                    Email: info@devote.is,<br/>
                    Phone: +387 66 343 528<br/><br/>

                    on the collection,
                    use and disclosure of your information that we collect
                    when you use our website ( devote.is ).
                    (the “Service”). By accessing or using the
                    Service, you are consenting to the collection, use and
                    disclosure of your information in accordance with this
                    Privacy Policy. If you do not consent to the same,
                    please do not access or use the Service.
                  </p>

                  <p className="privacy-policy-p">
                    We may modify this Privacy Policy at any time without
                    any prior notice to you and will post the revised
                    Privacy Policy on the Service. The revised Policy will
                    be effective 180 days from when the
                    revised Policy is posted in the Service and your
                    continued access or use of the Service after such time
                    will constitute your acceptance of the revised Privacy
                    Policy. We therefore recommend that you periodically
                    review this page.
                  </p>


                  <div>
                    <h3>How We Share Your Information</h3>
                    <p>
                      We will not transfer your personal information
                      to any third party without seeking your consent,
                      except in limited circumstances as described
                      below:
                    </p>

                    <h4>Analytics</h4>
                    <p className="privacy-policy-p">
                      We require such third party’s to use the
                      personal information we transfer to them only
                      for the purpose for which it was transferred and
                      not to retain it for longer than is required for
                      fulfilling the said purpose.
                    </p>
                    <p className="privacy-policy-p">
                      We may also disclose your personal information
                      for the following: (1) to comply with applicable
                      law, regulation, court order or other legal
                      process; (2) to enforce your agreements with us,
                      including this Privacy Policy; or (3) to respond
                      to claims that your use of the Service violates
                      any third-party rights. If the Service or our
                      company is merged or acquired with another
                      company, your information will be one of the
                      assets that is transferred to the new owner.
                    </p>
                    <h3 className="privacy-policy-h2">
                      Your Rights
                    </h3>

                    <p className="privacy-policy-p">
                      Depending on the law that applies, you may have
                      a right to access and rectify or erase your
                      personal data or receive a copy of your personal
                      data, restrict or object to the active
                      processing of your data, ask us to share (port)
                      your personal information to another entity,
                      withdraw any consent you provided to us to
                      process your data, a right to lodge a complaint
                      with a statutory authority and such other rights
                      as may be relevant under applicable laws. To
                      exercise these rights, you can write to us at
                      info@devote.is.
                      We will respond to your
                      request in accordance with applicable law.
                    </p>


                    <p className="privacy-policy-p">
                      Do note that if you do not allow us to collect
                      or process the required personal information or
                      withdraw the consent to process the same for the
                      required purposes, you may not be able to access
                      or use the services for which your information
                      was sought.
                    </p>


                    <h3>Cookies Etc.</h3>


                    <p className="privacy-policy-p">
                      To learn more about how we use these
                      and your choices in relation to these tracking
                      technologies, please refer to our <a href="/cookie-policy">Cookie Policy.</a>
                    </p>


                    <h3 className="privacy-policy-h2">
                      Security
                    </h3>

                    <p className="privacy-policy-p">
                      The security of your information is important to
                      us and we will use reasonable security measures
                      to prevent the loss, misuse or unauthorized
                      alteration of your information under our
                      control. However, given the inherent risks, we
                      cannot guarantee absolute security and
                      consequently, we cannot ensure or warrant the
                      security of any information you transmit to us
                      and you do so at your own risk.
                    </p>

                    <h3>Grievance / Data Protection Officer</h3>

                    <p className="privacy-policy-p">
                      If you have any queries or concerns about the
                      processing of your information that is available
                      with us, you may email our Grievance Officer at<br/>
                      Uslužna djelatnost "Devote",<br/>
                      Beogradska 11, 71123 Istočno Sarajevo,<br/>
                      Republika Srpska, Bosnia and Herzegovina,<br/>
                      Email: info@devote.is.<br/>
                      We will address your concerns in accordance with applicable law.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass=""/>
      </main>
    </>
  );
};

export default PrivacyPolicy;
