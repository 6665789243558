import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import {FaCompress, FaCode, FaGlobe} from 'react-icons/fa';


const WhyChooseOne = () => {
    return (
        <div className="section section-padding bg-color-light">
            <div className="container">
                <div className="why-choose-us pt-4">
                    <SectionTitle
                        subtitle="Building solutions, #poweringSuccess"
                        title="Building your ideas"
                        description=" We are passionate about turning your ideas into reality. We believe that every great
                                    business begins with a spark of inspiration, and we are here to help you bring that
                                    vision to life. Our team of skilled professionals is dedicated to building
                                    innovative solutions that align with your goals and drive your success."
                        textAlignment="heading"
                        textColor=""
                    />
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                                                   alt="circle"/></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                   alt="Line"/></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                   alt="Line"/></li>
            </ul>
        </div>

    )
}

export default WhyChooseOne;
