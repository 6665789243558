import React from 'react';
import {Link} from 'react-router-dom';
import {FaAngleDown} from "react-icons/fa";


const Nav = ({MobileHandler}) => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="/">Home</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Our Services <FaAngleDown/></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/web-development"}
                                  onClick={MobileHandler}>Web Development</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/e-commerce-solutions"}
                                  onClick={MobileHandler}>E-Commerce Solutions</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/design-branding"} onClick={MobileHandler}>Design & Branding</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/about-us">About Us</Link>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact-us"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;
