import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Collaborative Approach",
        para: "We value collaboration and believe that the best results come from working closely with our clients. We listen attentively to your ideas, goals, and challenges, and incorporate your valuable input throughout the entire development process. Your vision is at the heart of everything we do."
    },
    {
        id: 2,
        title: "Technical Expertise",
        para: "With years of experience in the industry, our team possesses the technical expertise to transform your ideas into robust, scalable solutions. From web development and e-commerce solutions to design and branding, we have the skills and knowledge to handle diverse projects across various industries."
    },
    {
        id: 3,
        title: "Innovation and Creativity",
        para: "We thrive on innovation and embrace creativity in all aspects of our work. We bring fresh ideas to the table and find inventive solutions to complex challenges. Our goal is to deliver unique and impactful solutions that set you apart from your competitors and leave a lasting impression on your audience."
    },
    {
        id: 4,
        title: "Attention to Detail",
        para: "We pay meticulous attention to detail in every project we undertake. From the initial planning phase to the final execution, we ensure that every element is thoughtfully crafted and meticulously executed. Our commitment to excellence guarantees that your project will meet the highest standards of quality."
    }
]

const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description="Your Vision, Our Expertise: Collaborate for Unmatched Solutions"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;
