import React from 'react';
import {Link} from 'react-router-dom';
import ServiceData from "../../data/service/ServiceMain.json";

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4">
                            <div className="footer-widget border-end">
                                <div className="header-logo">
                                    <img className="light-version-logo"
                                         src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo"/>
                                </div>
                                <div className="text-gray-400 mt-2">
                                    Building solutions, #poweringSuccess
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link
                                                            to={process.env.PUBLIC_URL + `/${data.slug}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Contact Us</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={'#'}>hello@devote.is</Link></li>
                                                <li><Link to={'#'}>+387 (0)66 343 528</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Company</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link
                                                  to={process.env.PUBLIC_URL + '/privacy-policy'}>Privacy Policy</Link>
                                                </li>
                                                <li><Link
                                                  to={process.env.PUBLIC_URL + '/cookie-policy'}>Cookie Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-copyright">
                                <span className="copyright-text">© Devote Solutions {new Date().getFullYear()}. All rights reserved.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;
